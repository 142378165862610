import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //  拖拽组件
import paging from "@/components/pagings.vue";
import sorttable from "@/components/sortTemplate.vue";
import inputnumber from '@/components/inputNumber.vue'

export default {
    inject: ["reload"],
    components: {
        draggable,
        sorttable,
        paging,
        inputnumber,
    },
    data() {
        return {
            isActive: true, //  输入框绑定动态class
            judge: false, //  输入框绑定动态class

            MoreConditions: '更多条件', //  输入框绑定动态class
            activeName: 'first',
            queryData: {
                stockStatus: 'wei:stock_status',
                checkStatus: '',
                courierNumber: "",
                createEndTime: "",
                createStartTime: "",
                expressName: "",
                isRejection: "",
                memberId: "",
                nickname: "",
                pageStart: "",
                pageTotal: "",
            }, //  待入库表格搜索表单
            time_second: [],
            inspectionStatusList: [], //  验货状态列表
            rejectionStatusList: [], //  拒签状态
            stockStatusArr: [], //  入库状态
            cscArr: [], //  快递公司下拉数组
            dialogImageUrl: '',
            dialogVisible: false,
            CancelSignIn: false,
            viewPicture: false,
            WarehouseList: [], //仓库数组
            packageStatusList: [], ////包裹状态
            queryData1: {
                stockStatus: 'ru_ku:stock_status'
            },

            // ======公用组件==========
            pageNum: 1, //页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //一页几条
            pageStart: 1, //页码
            pageCount: 0,
            pageTotal: 50, //一页几条
            nextPage: false, //是否有下一页
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            myTableHeard: [], //我的表头
            originalTabelHeadeTitle: [],
            myTableHeard1: [], //我的表头
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            allCheck1: false, //全选
            tableHeigth: "50vh",
            tabelHeadeTitle: [{
                name: "仓库",
                field: "storageName",
                width: "120",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递单号",
                field: "courierNumber",
                width: "180",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "memberNickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "120",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货架号",
                field: "frameName",
                width: "120",
                sort: 6,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "包裹状态",
                field: "stockStatusShow",
                width: "120",
                sort: 7,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "申请拒收",
                field: "isRejectionShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "验货申请",
                field: "checkStatusShow",
                width: "120",
                sort: 10,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "货物品类",
                field: "itemName",
                width: "120",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "单价",
                field: "unitPrice",
                width: "120",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "数量",
                field: "boxCount",
                width: "120",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },

            {
                name: "包裹备注",
                field: "packageComment",
                width: "160",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹新建时间",
                field: "packageCreateTime",
                width: "180",
                sort: 20,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "新建人(无)",
                field: "",
                width: "120",
                sort: 20,
                isShow: true,
                isTotal: false,
                isSort: true,
            },


            ],
            tabelHeadeTitle1: [

                {
                    name: "仓库",
                    field: "storageName",
                    width: "120",
                    sort: 2,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "快递单号",
                    field: "courierNumber",
                    width: "160",
                    sort: 5,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员名称",
                    field: "memberNickname",
                    width: "120",
                    sort: 3,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "会员号",
                    field: "memberId",
                    width: "120",
                    sort: 4,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "团号",
                    field: "invitationCode",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库状态",
                    field: "stockStatusShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "快递公司",
                    field: "expressName",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },


                {
                    name: "申请拒收",
                    field: "isRejectionShow",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "验货状态",
                    field: "checkStatusShow",
                    width: "120",
                    sort: 10,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "货物品类",
                    field: "itemName",
                    width: "120",
                    sort: 11,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹件数",
                    field: "boxCount",
                    width: "120",
                    sort: 12,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹重量(KG)",
                    field: "packageWeight",
                    width: "120",
                    sort: 13,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "包裹体积(m³)",
                    field: "volume",
                    width: "120",
                    sort: 14,
                    isShow: true,
                    isTotal: true,
                    isSort: true,
                },
                {
                    name: "长宽高(cm)",
                    field: "vlumeDetail",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "包裹备注",
                    field: "packageComment",
                    width: "120",
                    sort: 15,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "入库时间",
                    field: "intoTime",
                    width: "160",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },
                {
                    name: "操作人",
                    field: "intoUserName",
                    width: "120",
                    sort: 1,
                    isShow: true,
                    isTotal: false,
                    isSort: true,
                },


            ],
            tableData: [],
            tableData1: [],
            totalArr: [], //需要合计的字段
            totalArr1: [], //需要合计的字段
            className: "", //导出需要
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'PackageWarehousing', //页面标识
            fullscreen: false,
            TableStyle: true,
            FormData: { //签收
                count: '', //件数 必填
                courierNumber: '', //快递单号 必填
                sign: '', //	签名密匙 必填
                expressName: '', //快递公司
                memberId: '', //会员号
                storageId: '', //仓库ID
            },
            choice: '', //勾选件数
            choice1: '', //勾选件数
            singleData: [], //  表格勾选的件数
            // ======公用组件==========
            courierNumberArr: [],
            originalTabelHeadeTitle1: [],
            // 输入快递单号弹窗
            showInputBox: false, // 
        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }

    },
    created() {

        this.uploadUrl = this.common.uploadUrl;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }

        this.getCscArr(); //  获取快递公司
        this.getStatusValList('bill_inspection_v1.check_status'); //  验货状态列表
        this.getStatusValList('bill_rejection_v1.handle_status'); //  拒收单拒收状态
        this.getStatusValList('package_v1.stock_status'); //  入库状态
        // this.getStatusValList('package_v1.package_status');            //  包裹状态
        this.getStorageList(); //   仓库列表


        let endTime = tools.getCurrentDate(); //今天的时间;
        let startTime = tools.getFlexDate(-10); //30天前
        let arrs = [startTime, endTime]
        this.time_second = arrs
        this.queryData.createStartTime = startTime
        this.queryData.createEndTime = endTime
        this.getGroupFindFieldSort(); //获取用户自定义表头
    },
    beforeDestroy() {
        // 取消监听事件
        window.removeEventListener('resize', this.onresize)
        window.removeEventListener('keydown', this.keydown)
    },
    mounted() {
        // this.getData();
    },
    activeName() {

    },

    watch: {

    },
    methods: {
        rowclick(row, event, column) {
            this.$refs.mytable.toggleRowSelection(row);
        },
        rowclick2(row, event, column) {
            this.$refs.mytable1.toggleRowSelection(row);
        },
        //点击输入快递单号
        clickInput() {
            this.showInputBox = true
        },
        showInputBoxBtn(val) {
            this.showInputBox = false
            this.queryData.courierNumber = val
            this.getData();
        },
        closeDiasInputVal(val) {
            this.showInputBox = val || false
        },

        getData() { //  获取数据
            let that = this;
            let str = this.queryData.courierNumber;
            let courierNumberArr = [];
            if (str) {
                courierNumberArr = tools.getOrderNum(str)
            }

            if (that.queryData.createEndTime) {
                that.queryData.createEndTime = that.queryData.createEndTime + " 23:59:59";
            }
            if (that.queryData.createStartTime) {
                that.queryData.createStartTime = that.queryData.createStartTime + " 00:00:00";
            }

            // if (this.time_second && this.time_second.length > 1) {
            //     this.queryData.createStartTime = `${this.time_second[0]} 00:00:00`
            //     this.queryData.createEndTime = `${this.time_second[1]} 23:59:59`
            // } else {
            //     this.queryData.createStartTime = '';
            //     this.queryData.createEndTime = '';
            // }
            let param = {
                checkStatus: this.queryData.checkStatus,
                courierNumber: courierNumberArr,
                createEndTime: this.queryData.createEndTime,
                createStartTime: this.queryData.createStartTime,
                expressName: this.queryData.expressName,
                isRejection: this.queryData.isRejection, //是否拒收0=否1=是
                memberId: this.queryData.memberId,
                nickname: this.queryData.nickname,
                pageStart: this.pageNum,
                pageTotal: this.size,
                stockStatus: this.queryData.stockStatus,

            }
            Api.instockList(param).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    if (this.activeName === 'second') {
                        this.tableData1 = res.data.result.data || [];
                        this.pageCount = res.data.result.pageCount || 0;
                    } else {
                        this.tableData = res.data.result.data || [];
                        this.total = res.data.result.pageCount || 0;
                    }
                    this.fetTableHeight();
                }
            });
            if (that.queryData.createEndTime) {
                that.queryData.createEndTime = that.queryData.createEndTime.slice(0, 10);
            }
            if (that.queryData.createStartTime) {
                that.queryData.createStartTime = that.queryData.createStartTime.slice(0, 10);
            }
        },
        tableRowClassName({
            row,
            rowIndex
        }) { //  表格样式i
            if (rowIndex % 2 === 0) {
                return "warning-row";
            }
        },
        changeTimes(e) {
            console.log(e)
            if (e) {

            } else {
                this.time_second = [];
            }
            this.queryBtn_ok();
        },

        queryBtn_ok() { //  查询按钮
            this.pageNum = 1;
            this.getData();
        },
        resetNormal() { //  重置查询表单
            this.queryData = {}
            this.getData();
        },
        getStatusValList(status) { //  获取状态展示值
            let param = {
                tableAndFieldName: status,
            }
            Api.getStatusValList(param).then((res) => {
                if (res.data.status === 'success') {
                    if (status === "package_v1.stock_status") { //  入库状态
                        this.stockStatusArr = res.data.result || [];
                    }
                    if (status === "bill_rejection_v1.handle_status") { //  拒签状态
                        this.rejectionStatusList = res.data.result || [];
                    }
                    if (status === "bill_inspection_v1.check_status") { //  验货单验货状态
                        this.inspectionStatusList = res.data.result || [];
                    }
                } else {
                    console.log(`${res.data.message} ---状态展示值`)
                }
            });

        },
        getCscArr() { //  获取快递公司数组
            Api.expressChooseList().then((res) => {
                if (res.data.status === "success") {
                    this.cscArr = res.data.result
                } else {
                    console.log(`${res.data.message}--快递公司数组`)
                }
            });
        },

        getStorageList() { //  获取仓库下拉列表
            Api.getStorageList().then((res) => {
                if (res.data.status === 'success') {
                    this.WarehouseList = res.data.result || [];
                } else {
                    console.log(`${res.data.message} ---仓库下拉列表`)
                }
            });
        },
        //取消入库
        CancelWarehousing() {
            if (this.singleData.length >= 1) {
                let param = new Object
                let nidList = []
                this.singleData.forEach((item) => {
                    nidList.push(item.packageId)
                })
                param.packageIds = nidList
                let sign = tools.getSign(param)
                param.sign = sign
                Api.cancelRuKuPackage(param).then((res) => {
                    if (res.data.status == "success") {
                        this.$message.success("取消入库成功")
                        this.getData()
                    }

                })
            } else {
                this.$message.warning("请至少选择一条数据")
            }
        },
        // 智能入库
        autoRuKu() {
            this.$router.push('/BusinessManagement/Warehousing/IntelligentWarehous')
        },
        rukuqueren() { //  入库确认
            if (this.activeName === 'first') {
                if (this.singleData.length === 1) {
                    if (this.singleData[0].stockStatus == "ru_ku:stock_status") {
                        this.$message.warning('该包裹已入库,不能在入库')
                    } else {
                        this.$router.push({
                            path: '/BusinessManagement/Warehousing/ReceiptConfirmation',
                            query: {
                                packageId: this.singleData[0].packageId,

                            }
                        })
                    }
                } else if (this.singleData.length < 1) {
                    this.$router.push('/BusinessManagement/Warehousing/ReceiptConfirmation')
                } else {
                    this.$message.warning('请选择一条数据')


                }
            } else {
                if (this.singleData.length === 1) {
                    this.$router.push({
                        path: '/BusinessManagement/Warehousing/ReceiptConfirmation',
                        query: {
                            update: this.singleData[0].packageId,

                        }
                    })
                } else {
                    this.$message.warning('请选择一条数据')
                }
            }
        },


        modifybut() { //  修改
            if (this.singleData.length === 1) {
                this.$router.push({
                    path: '/BusinessManagement/Warehousing/ReceiptConfirmation',
                    query: {}
                })
            } else {
                this.$message.warning('请选择一条数据')
            }
        },
        handleCommandTabs(m) {
            if (m == "a") {
                this.myModel()
            } else if (m == 'b') {
                this.exportBtn()
            } else if (m == 'c') {
                this.exportTemplateDownload()
            } else if (m == 'd') {
                this.upLoadBtn()
            } else {
                this.screen()
            }

        },

        //输入框绑定动态class
        more() {
            this.fetTableHeight()
            this.isActive = !this.isActive
            this.judge = !this.judge
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
        },
        rejection() { //  新建拒收

            this.$router.push({
                path: '/BusinessManagement/Warehousing/RejectionTreatment',
                query: {
                    status: "newAdd"
                }
            });
        },
        rejection2(row) { //  拒收处理
            let arrs = this.singleData || [];
            if (arrs.length != 1) {
                this.$message.error('请选择一条数据');
                return false
            }
            this.$router.push({
                path: '/BusinessManagement/Warehousing/RejectionTreatment?number=' + arrs[0].courierNumber,
                query: {
                    status: "edit"
                }
            });
        },
        //付款确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        //上传图片
        handleRemove(file, fileList) {
            //console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },

        //新建验货
        InspectionHandling() {

            this.$router.push({
                path: '/BusinessManagement/Warehousing/InspectionHandling',
                query: {
                    pageType: 1,

                }
            })

        },
        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            //console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status == "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldNames.push(this.myTableHeard[i].field);
                        heads.push(this.myTableHeard[i].name);
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let that = this;
            let datas = that.tableData;
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: that.className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            Api.templateDataExport({
                customizedExportId: customizedExportId,
                className: that.className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            for (var i = 0; i < this.myTableHeard.length; i++) {
                if (this.myTableHeard[i].isShow) {
                    fieldName.push(this.myTableHeard[i].field);
                    head.push(this.myTableHeard[i].name);
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.activeName === 'second') {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className,
                            this.tableData1,
                            "包裹已入库列表"
                        );
                    } else {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "包裹待入库列表"
                        );
                    }

                }
            });
        },
        // ===============================================导出部分=结束======

        /**
         * ******************************计算表格高度**开始*************************
         */
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth = 50;
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === 'second') {
                    // queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                    queryHeigth = 20 //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 + 15;
            return Math.floor(windonHeight - otherHeight) || "50vh"; //
        },
        /**
         * ******************************计算表格高度**结束*************************
         */

        // 刷新页面==>
        reload_s() {
            this.reload(); //App.Vue里面的方法
        },
        //顶部标签页切换
        handleClick() {
            this.queryData.courierNumber = ""
            this.pageNum = 1;
            if (this.activeName === 'second') {
                this.pageGroupName = 'PackageWarehousing1'
                this.queryData.stockStatus = "ru_ku:stock_status"
                this.SignInRecord()

            } else {
                this.pageGroupName = 'PackageWarehousing'
                this.queryData.stockStatus = "wei:stock_status"
                this.getGroupFindFieldSort()
            }
            // this.getData()
        },
        //签收记录表头获取
        SignInRecord() {
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        this.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    this.myTableHeard1 = this.tabelHeadeTitle1;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isTotal) {
                        totalArr.push(this.myTableHeard1[i].field);
                    }
                }
                this.totalArr1 = totalArr;
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });

            this.getData()
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            let that = this;
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        that.myTableHeard = JSON.parse(arr[0].sortObject);
                        that.sortName = arr[0].sortName;
                    }
                } else {
                    that.myTableHeard = that.tabelHeadeTitle;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < that.myTableHeard.length; i++) {
                    if (that.myTableHeard[i].isTotal) {
                        totalArr.push(that.myTableHeard[i].field);
                    }
                }
                that.totalArr = totalArr;
            });

            that.$nextTick(() => {
                that.$refs.mytable.doLayout();
            });
            this.getData()
        },
        // 设置模板按钮
        myModel() {
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            this.show_sortTableHeard1 = true;
            this.tabelHeadeTitle1 = JSON.parse(JSON.stringify(this.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle1.length; i++) {
                if (this.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength1 = selectLength;
            if (selectLength === this.tabelHeadeTitle1.length) {
                this.allCheck1 = true;
            } else {
                this.allCheck1 = false;
            }
        },
        // 表格选择事件(待入库)
        handleSelectionChange(e) {
            this.singleData = e
        },
        // 表格选择事件(入库记录)
        handleSelectionChange1(e) {
            this.singleData = e
        },
        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },

        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },

        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard1 = e;
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property === that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },

        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },
        // 上一页
        handlePrevPage() {
            this.pageNum = this.pageNum - 1 || 1;
            this.getData();
        },
        // 下一页
        handleNextPage() {
            this.pageNum = this.pageNum + 1 || 1;
            this.getData();
        },
    }
}